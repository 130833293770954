// @flow

import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/Layout'

import './legal.scss'

type Props = {}

export default class TokensPage extends Component<Props> {
  render() {
    return (
      <Layout className="legal-page">
        <Helmet title="Privacy - Mainframe - Decentralized Application Platform" />
        <div className="container">
          <h1>Privacy Policy</h1>
          <p>
            This Privacy Policy is incorporated by this reference into the
            Mainframe Group, Inc. Terms of Use (the “TOU”). The terms
            “Mainframe,” “we,” “our” and “us” include Mainframe Group, Inc. and
            our affiliates and subsidiaries. All other capitalized terms not
            defined in this Privacy Policy will have the meanings set forth in
            the TOU. This Privacy Policy explains how Mainframe may collect,
            use, and disclose information we obtain through our website at
            https://mainframe.com (the “Site”) and users of the Onyx web and
            mobile application (“Onyx”).
          </p>
          <p>
            If you choose to use the services provided on the Site or Onyx
            (collectively, the “Services”), then you agree to the collection and
            use of information in relation with this Privacy Policy. The
            personal information that we collect is used for providing and
            improving the Services. We will not use or share your information
            with anyone except as described in this Privacy Policy.
          </p>
          <h2>Information Mainframe Collects From You</h2>
          <p>
            We collect information about you directly from you as well as
            automatically through your use of our Site or Onyx. The limited
            information we collect is used to provide the Services to you.
          </p>

          <h3>Information We Collect Directly From You</h3>
          <p>
            Mailing List Sign-Up. We collect your email address when you sign up
            on the Site to receive updates about upcoming product launches.
          </p>
          <p>
            Customer Support. We collect information that you provide to us
            through your communications with our customer-support team. For
            Example, your name and email address.
          </p>
          <p>
            Ethereum Address. If you create an account on our Site, we will
            collect your Ethereum wallet address.
          </p>
          <h3>Information We Collect Automatically From You</h3>
          <p>
            When you visit the Site or Onyx, we and our third party service
            providers may automatically collect the following information about
            your use of our Site through cookies and other technologies: your
            domain name; your browser type and operating system; web pages you
            view; links you click; your IP address and geo-location data; your
            mobile device ID; the length of time you visit our Site; the
            referring URL, or the webpage that led you to our Site. We may
            combine this information with other information that we have
            collected about you, including, where applicable, your name, and
            other personal information. Please see the section “How Mainframe
            Uses Cookies and Other Tracking Mechanisms” below for more
            information. By using the Site, you are authorizing us to gather,
            parse, and retain data related to the provision of the Site.
          </p>

          <h3>Information We Do Not Collect</h3>
          <p>
            Mainframe does not process, collect or see any communications or
            information you send through our messaging Services, including, but
            not limited to, messages and information transmitted through Onyx.
            Information sent through our messaging Services is encrypted before
            it is transmitted to our servers. As a result, we do not see the
            content of messages sent using our Services, and cannot reveal to
            others information about your messages or how you use our Services,
            aside from the limited non-encrypted information, such as the date
            your account was created, the date of last use and the type of
            device on which such account was installed. Mainframe does not log
            or store metadata associated with your use of the Services.
          </p>
          <p>
            When you use our Services, it is important to note that only
            recipients of your messages will be able to open encrypted files
            using the Services. Messages you send to another user may remain on
            their device after you delete it from yours. If you do not trust the
            person you are messaging through the Services, do not open files
            coming from them or send them files or information you do not want
            to be saved.
          </p>
          <p>
            We do not collect any information regarding your location through
            your use of the Services.
          </p>
          <h2>How Mainframe Uses Your Information</h2>
          <p>
            Internal and Site-Related Usage. We may use and retain any
            information that we collect on the Site to provide, maintain and
            improve the Site and our products and services, and to otherwise
            conduct our business. One of the service providers that we use for
            data analytics is Google Analytics. If you do not want Google
            Analytics to collect and use information about your use of the Site,
            then you can install an opt-out in your web browser{' '}
            <a href="https://tools.google.com/dlpage/gaoptout/" target="_new">
              (https://tools.google.com/dlpage/gaoptout/)
            </a>
            .
          </p>
          <p>
            Communications. We may send emails to the email address you provide
            to us to provide you with updates and news about Mainframe and/or
            the Site and for operational purposes, such as customer service or
            system maintenance.
          </p>
          <p>
            Marketing. We may use information that we collect on the Site to
            provide online advertising on the Site and to facilitate transmittal
            of information that may be useful, relevant, valuable or otherwise
            of interest to you.
          </p>
          <p>
            Aggregate Data. We may de-identify and aggregate data collected
            through the Site and use it for any lawful purpose.
          </p>
          <p>
            To the extent required under applicable privacy law we rely on the
            following legal bases to process your personal information:
          </p>
          <p>Contract: We collect your email address and Ethereum Address.</p>
          <h2>How Mainframe Uses Cookies and Other Tracking Mechanisms</h2>
          <p>
            We use cookies and other tracking mechanisms to track information
            about your use of our Site and Services. We and our third party
            service providers use cookies and other tracking mechanisms to track
            information about your use of our Site. We may combine this
            information with other personal information we collect from you (and
            our third party service providers may do so on our behalf).
          </p>
          <p>
            Cookies. Cookies are alphanumeric identifiers that we transfer to
            your computer’s hard drive through your web browser for
            record-keeping purposes. Some cookies allow us to make it easier for
            you to navigate our Site and Onyx, while others are used to enable a
            faster log-in process or to allow us to track your activities at our
            Site and Onyx. There are two types of cookies: session and
            persistent cookies.
          </p>
          <p>
            Session Cookies. Session cookies exist only during an online
            session. They disappear from your computer when you close your
            browser or turn off your computer. We use session cookies to allow
            our systems to uniquely identify you during a session or while you
            are logged into the Sites. This allows us to process your online
            transactions and requests and verify your identity, after you have
            logged in, as you move through our Sites.
          </p>
          <p>
            Persistent Cookies. Persistent cookies remain on your computer after
            you have closed your browser or turned off your computer. We use
            persistent cookies to track aggregate and statistical information
            about user activity.
          </p>
          <p>
            Disabling Cookies. Most web browsers automatically accept cookies,
            but if you prefer, you can edit your browser options to block them
            in the future. The Help portion of the toolbar on most browsers will
            tell you how to prevent your computer from accepting new cookies,
            how to have the browser notify you when you receive a new cookie, or
            how to disable cookies altogether. Visitors to our Site who disable
            cookies will not be able to browse certain areas of the Site or use
            the Onyx.
          </p>
          <p>
            Do Not Track. Some web browsers incorporate a “Do Not Track”
            feature. Because there is not yet an accepted standard for how to
            respond to Do Not Track signals, our Site does not currently respond
            to such signals
          </p>
          <h2>Mainframe May Disclose Your Information</h2>
          <p>
            We Use Vendors and Service Providers. We may share any information
            that we receive through the Site and Onyx with vendors and service
            providers retained in connection with the provision of the Site. We
            may use these tools to help us improve our Site, Onyx, performance
            and user experiences. For example, we use Google Analytics to
            evaluate usage of our Site and Onyx, and Amazon Web Services for
            cloud computing services and to store your Ethereum wallet address.
            To opt out from having your data used by Google Analytics, please
            visit Google’s opt-out page at{' '}
            <a href="https://tools.google.com/dlpage/gaoptout/">
              https://tools.google.com/dlpage/gaoptout/
            </a>
            . We also use third party communication services such as Telegram to
            provide updates to our community regarding our platform and
            Services. These entities may use cookies and other tracking
            technologies to perform their services. Any information sent through
            such third party service will be subject to the privacy policies of
            such third party.
          </p>
          <p>
            Marketing. We do not rent, sell, or share personal information (as
            defined by California Civil Code § 1798.83) collected through the
            Site about you with other people or non affiliated companies for
            their direct marketing purposes, unless we have your permission.
          </p>
          <p>
            As Required By Law and Similar Disclosures. We may access, preserve,
            and disclose your information if we believe doing so is required or
            appropriate to: (1) comply with law enforcement requests and legal
            process, such as a court order or subpoena; (2) respond to your
            requests; or (3) protect your, our or others’ rights, property, or
            safety.
          </p>
          <p>
            Merger, Sale, or Other Asset Transfers. If we are involved in a
            merger, acquisition, financing due diligence, reorganization,
            bankruptcy, receivership, sale of assets, or transition of service
            to another provider, then your information may be sold or
            transferred as part of such a transaction as permitted by law and/or
            contract. The use of your information following any of the foregoing
            events will be governed by the provisions of the Privacy Policy in
            effect at the time such information was collected by Mainframe.
          </p>
          <p>
            Consent. We may also disclose your information with your permission.
          </p>
          <h2>Security</h2>
          <p>
            We have implemented commercially reasonable precautions to protect
            the information we collect from loss, misuse, and unauthorized
            access, disclosure, alteration, and destruction. Please be aware
            that despite our efforts, no data security measures can guarantee
            100% security.
          </p>
          <p>
            You should take steps to protect against unauthorized access to your
            password, mobile device, and computer by, among other things,
            signing off after using a shared computer, choosing a robust
            password that nobody else knows or can easily guess, and keeping
            your login and password private. We are not responsible for any
            lost, stolen, or compromised passwords or for any activity on your
            account via unauthorized password activity.
          </p>
          <h2>Children’s Privacy</h2>
          <p>
            Our Services do not address anyone under the age of 13. We do not
            knowingly collect personal identifiable information from children
            under 13. In the case we discover that a child under 13 has provided
            us with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            at <a href="mailto:legal@mainframe.com">legal@mainframe.com</a> so
            that we will be able to do necessary actions.
          </p>
          <h2>Access and Other Rights to Personal Information</h2>
          <p>
            You may access, update, correct, delete and obtain a copy your
            profile in the account associated with the Site and Onyx at any
            time. You may also request copies, modifications or deletions of
            personal information or submit other requests regarding your
            personal information that you have submitted to us by contacting us
            at legal@mainframe.com. Please note that copies of information that
            you have updated, modified or deleted may remain viewable in cached
            and archived pages of the Site for a period of time. Due to the
            decentralized nature of our Services, we do not receive or store
            messages sent through our Services. As a result, we cannot provide
            rights to any messages you send through our Services to you.
          </p>
          <h2>Retention of Your Information</h2>
          <p>
            We will store your personal information until you no longer maintain
            an account on our Site and Onyx. If we are required by law to retain
            your information longer or if your information is required for us to
            assert or defend against legal claims, we will retain your
            information until the end of the relevant retention period or until
            the claims in question have been settled. Please note that if you
            opt out of our retention of your data, we may not be able to provide
            our Services to you. You may contact us at legal@mainframe.com to
            opt out.
          </p>
          <h2>Location of Personal Information</h2>
          <p>
            We do not store any personal information or data from users except
            for any information provided to us for customer service purposes.
            Email addresses provided to us are stored by our third party email
            marketing service provider. Your Ethereum wallet address is stored
            by our third party cloud storage provider. These service providers
            store any personal information or data we collect from you in
            storage locations located here in the United States and throughout
            the world. If you access the Site or Onyx from outside the United
            States, you consent to the transfer, processing and storage of your
            personal information in and to the United States, which may provide
            protection for your personal information that varies from the
            protection provided by the jurisdiction in which you reside.
          </p>
          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this page periodically for any changes. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            These changes are effective immediately, after they are posted on
            this page.
          </p>
          <h2>Contact Information</h2>
          <p>
            If you have any questions this Privacy Policy or questions or
            complaints about our information practices, please contact us as
            noted below:
          </p>
          <p>
            Mainframe Group, Inc.
            <br />
            Telephone: (484) 918-0466
            <br />
            Email: <a href="mailto:legal@mainframe.com">legal@mainframe.com</a>
          </p>
          <p>
            In addition, you may also have the right to file complaints with the
            data protection authority in your jurisdiction of residence.
          </p>
        </div>
      </Layout>
    )
  }
}
